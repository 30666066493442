import * as React from 'react';
import {
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  MuiThemeProvider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { concat, filter, forEach, head, isEmpty, isNil, mapValues, pick, uniq } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import ServiceContainer from 'src/ServiceContainer';
import { modal } from 'src/components/Configure/Configure.style';
import { ToggleAction } from 'src/components/Configure/ConfigureModal';
import { GranularEditPayloadItem } from 'src/dao/pivotClient';
import { getValidValues } from 'src/pages/AssortmentBuild/StyleEdit/StyleEditSection/StyleEditSection.client';
import { BasicPivotItem } from 'src/worker/pivotWorker.types';
import { muiTheme } from 'src/utils/Style/Theme';
import { zConfigurableGridMultiSelectPostButton } from 'src/services/configuration/codecs/viewdefns/general';
import { getUrl, processApiParams } from 'src/pages/AssortmentBuild/StyleEdit/StyleEdit.utils';
import { z } from 'zod';
import { generateCoordinateValues } from './ConfigurableGrid.utils';
import { AppType } from 'src/services/configuration/codecs/bindings.types';
import { searchInputContainerStyle, tableStyle, styles } from './ActionModal.style';

export interface ActionModalProps {
  isOpen: boolean;
  // config
  onToggleModal: (action: ToggleAction) => void;
  selectedItems: Record<string, unknown>[];
  config?: z.infer<typeof zConfigurableGridMultiSelectPostButton>;
}
export interface Option {
  value: string | number;
  label: string;
}


export const ActionModal = ({ isOpen, onToggleModal, selectedItems, config }: ActionModalProps) => {
  const [dropdownData, setDropdownData] = useState<Option[]>([]);
  const [selections, setSelections] = useState<Option[]>([]);
  const [searchValue, setSearchValue] = useState<string>();
  const [selectAll, setSelectAll] = useState<boolean>(false);
  useEffect(() => {
    const firstItem = head(selectedItems);
    if (isNil(firstItem) || isNil(config)) {
      return;
    }
    const dataApi = config.params.dataApi;
    if (dataApi.isListData) {
      const processedParamsArray = selectedItems.map((item) => {
        return processApiParams(dataApi, item).params;
      });
      const joinedParams = mapValues(
        processedParamsArray.reduce((acc, item) => {
          forEach(item, (value, key) => {
            acc[key] = acc[key] ?? [];
            acc[key].push(value);
          });
          return acc;
        }, {}),
        (value, key) => {
          return uniq(value).join(',');
        }
      );
      ServiceContainer.pivotService
        .listData(dataApi.defnId, AppType.Assortment, {
          ...dataApi.params,
          ...joinedParams,
        })
        .then((res) => {
          setDropdownData((res.flat as unknown) as Option[]);
        });
    } else {
      const processedParams = processApiParams(dataApi, firstItem);
      getValidValues(getUrl(processedParams)).then((values) => {
        setDropdownData(
          values.map((v: Option) => {
            return {
              value: v.value,
              label: v.label,
            };
          })
        );
      });
    }
  }, [config, selectedItems]); // <- this should be the config, but I'll fix once I send in config

  const submitUpdate = useCallback(async () => {
    if (isNil(config) || isEmpty(selectedItems)) {
      return; // how did we even get here?
    }
    const { keysToCopy, keyToUpdate, coordinateMap } = config.params;
    const payload: GranularEditPayloadItem[] = (selectedItems as BasicPivotItem[]).map((item) => {
      return {
        coordinates: generateCoordinateValues(coordinateMap, item),
        ...pick(item, keysToCopy),
        [keyToUpdate]: selections.map((i) => i.value),
      };
    });
    await ServiceContainer.pivotService.granularEditSubmitData(payload);
  }, [config, selectedItems, selections]);

  const closeModal = useCallback(
    async (toggleType: ToggleAction) => {
      if (toggleType === 'apply') {
        // submit based on selections then close
        await submitUpdate();
      }
      onToggleModal(toggleType);
    },
    [onToggleModal, submitUpdate]
  );
  useEffect(() => {
    if (selections.length === dropdownData.length && dropdownData.length > 0) {
      setSelectAll(true); // All items are selected
    } else {
      setSelectAll(false); // Not all items are selected
    }
  }, [selections, dropdownData]);

  const createTable = useCallback(() => {
    if (isEmpty(dropdownData)) {
      return <></>;
    }
    return (
      <MuiThemeProvider theme={muiTheme}>
        <div className={searchInputContainerStyle}>
          <input
            type="text"
            placeholder="Search groups..."
            aria-label="Search groups"
            onChange={(ev) => setSearchValue(ev.currentTarget.value)}
            defaultValue={''}
          />
          <button
            className="select-all-btn"
            onClick={() => {
              const allSelected = !selectAll;
              if (allSelected) {
                setSelections(dropdownData);
              } else {
                setSelections([]);
              }
              setSelectAll(allSelected);
            }}
          >
            {selectAll ? 'Remove All' : 'Select All'}
          </button>
        </div>
        <div style={{ height: 300, width: 300, overflowY: 'auto' }}>
          <Table>
            <TableHead className={tableStyle}>
              <TableRow>
                <TableCell colSpan={2} style={{ textAlign: 'left' }}>
                  Selected: {selections.length}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Name</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {dropdownData
                .filter((i) => {
                  if (isNil(searchValue) || isEmpty(searchValue)) {
                    return true;
                  }
                  // Split the search value and filter out empty terms
                  const searchTerms = searchValue
                    .toLowerCase()
                    .split(';')
                    .filter((term) => !isEmpty(term.trim()));

                  // Check if any of the search terms are present in the label or id
                  return searchTerms.some(
                    (term) =>
                      i.label.toLowerCase().indexOf(term.trim()) >= 0 || (i.value as string).indexOf(term.trim()) >= 0
                  );
                })
                .map((item) => {
                  const { label: name, value } = item;
                  const isSelected = selections.map((s) => s.value).indexOf(value) >= 0;
                  const rowClass = isSelected ? styles.selected : styles.unselected;
                  return (
                    <TableRow
                      key={value}
                      className={rowClass}
                      onClick={() => {
                        setSelections((curSels) => {
                          const isSelected = curSels.map((s) => s.value).indexOf(value) >= 0;
                          if (isSelected) return filter(curSels, (s) => s.value !== value);
                          else return concat(curSels, item);
                        });
                      }}
                    >
                      <TableCell>{value}</TableCell>
                      <TableCell>{name}</TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </div>
      </MuiThemeProvider>
    );
  }, [dropdownData, searchValue, selections, selectAll]);
  const className = modal + ' configure-modal';

  const table = createTable();
  return (
    <Dialog
      open={isOpen}
      className={className}
      onClose={() => closeModal('close')}
      fullWidth={true}
      scroll={'paper'}
      maxWidth={'xl'}
    >
      <DialogTitle>
        <span className="left-container">
          <i className="fas fa-cog icon" />
          {config?.text}
        </span>
        <span className="right-container">
          <i className="far fa-times" onClick={() => closeModal('close')} />
        </span>
      </DialogTitle>
      <DialogContent>
        {table}
        <footer>
          <button onClick={() => closeModal('apply')} className="apply">
            <i className="far fa-check" />
            Apply
          </button>
          <button onClick={() => closeModal('close')} className="reset">
            <i className="fas fa-ban" />
            Reset
          </button>
        </footer>
      </DialogContent>
    </Dialog>
  );
};
